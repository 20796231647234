import "./globals.js";

import { createIcons, icons } from "lucide";

import Fuse from "fuse.js";
import { jobTpl } from "./utils.js";

const jobboardForm = $("#jobSearch");
const jobboardFormSearchButton = $("#jobSearch button");
const urlParams = new URLSearchParams(window.location.search);
const jobLists = {};

const noJobTpl = `
<article>
    <header>
        <h2>No Jobs Found</h2>
    </header>
    <p>We couldn’t find any jobs matching your search criteria.</p>
</article>
`;

// detect language and set default county, page init script
const countrySelector = $('select[name="country"]');
countrySelector.value = navigator.language.slice(-2);

// fallback selector to UK
if (countrySelector.value === "") {
	countrySelector.value = "UK";
}

const setJobboardHistory = (currentPage, country, searchPattern) => {
	history.pushState(
		{ page: currentPage, country, searchPattern },
		`Page ${currentPage}, pattern ${searchPattern}, country ${country}`,
		`?page=${currentPage}&country=${country}&searchPattern=${searchPattern}`,
	);
};

const renderJobBoard = (searchResult, currentPage, searchPattern, country) => {
	const jobboard = $("#jobboard");
	const jobsPerPage = 6; // Number of jobs per page
	const maxPages = 20;

	if (searchResult.length > 0) {
		// Calculate the slice of jobs to display
		const end = currentPage * jobsPerPage;
		const jobPage = searchResult.slice(0, end);

		// Determine if the "Show More" button should be displayed
		const howMuchMore = Math.max(0, Math.min(6, searchResult.length - end));
		const moreButton =
			end < searchResult.length && currentPage < maxPages
				? `<hr><div class='show-more'><button id="showMore">Show ${howMuchMore} more jobs</button></div>`
				: "";

		const moreSpecific =
			currentPage === maxPages
				? '<hr><div class="show-more"><p>Make you search more specific to get better results</p></div>'
				: "";

		// Render the job board
		jobboard.innerHTML = `
          <p>Showing results 1-${currentPage * jobsPerPage < searchResult.length ? currentPage * jobsPerPage : searchResult.length} of ${searchResult.length} JS jobs</p>
          <hr>
          <div class="grid jobboard" id="jobboard">
            ${jobPage.map((result, index) => jobTpl(result.item, jobboardForm.dataset.details)).join("")}
          </div>
          ${moreButton}${moreSpecific}
        `;

		// Add event listener for the "Show More" button
		const moreBtn = $("#showMore");
		if (moreBtn) {
			moreBtn.addEventListener("click", () => {
				const newPage = currentPage + 1;
				// Update the browser's history
				setJobboardHistory(newPage, country, searchPattern);
				renderJobBoard(searchResult, newPage, searchPattern, country); // Re-render with the next set of jobs
			});
		}
	} else {
		jobboard.innerHTML = noJobTpl;
	}

	// reinit icons
	createIcons({ icons });
};

const jobSearch = async () => {
	const searchPattern = jobboardForm.querySelector(
		'#jobSearch input[name="search"]',
	).value;
	const country = jobboardForm.querySelector(
		'#jobSearch select[name="country"]',
	).value;

	// cache per country, AJAX fetch
	if (!jobLists[country]) {
		jobboardFormSearchButton.setAttribute("aria-busy", "true");

		const response = await fetch(
			`${jobboardForm.dataset.url}?country=${country}`,
		); // config path
		jobLists[country] = await response.json();
	}

	jobboardFormSearchButton.removeAttribute("aria-busy");

	const fuseOptions = {
		includeScore: true,
		keys: [
			{
				name: "title",
				weight: 3,
			},
			{
				name: "tags",
				weight: 9,
			},
			{
				name: "city",
				weight: 9,
			},
			{
				name: "company",
				weight: 9,
			},
		],
	};

	// this part can be removed if there a lot of jobs posted, for now show all if no searchPattern!
	// map to mimic fuse result
	let searchResult = jobLists[country].map((job) => ({ item: job }));

	if (searchPattern.trim() !== "") {
		// this is the fuzzy search
		const fuse = new Fuse(jobLists[country], fuseOptions);
		// Join the terms back together with spaces (CONST if if removed)
		searchResult = fuse.search(searchPattern);
	}

	// get page from url if reload
	const pageParam = urlParams.get("page");
	const page =
		Number.isInteger(Number(pageParam)) && pageParam !== null
			? Number(pageParam)
			: 1;

	// after search, page 1
	renderJobBoard(searchResult, page, searchPattern, country);
};

jobboardForm.addEventListener("submit", async (e) => {
	e.preventDefault(); // Stops the form submission
	// Change the pattern
	const searchPattern = jobboardForm.querySelector(
		'#jobSearch input[name="search"]',
	).value;
	const country = jobboardForm.querySelector(
		'#jobSearch select[name="country"]',
	).value;

	setJobboardHistory(1, country, searchPattern);
	jobSearch();
});

// INIT page
(() => {
	// window reload, check params and perform search
	const reloadPage = urlParams.get("page");
	const reloadCountry = urlParams.get("country");
	const reloadSearchPattern = urlParams.get("searchPattern");

	if (reloadPage && reloadCountry && reloadSearchPattern) {
		// fill form
		jobboardForm.querySelector('#jobSearch input[name="search"]').value =
			reloadSearchPattern;
		jobboardForm.querySelector('#jobSearch select[name="country"]').value =
			reloadCountry;
		jobSearch();
	}

	createIcons({ icons });
})();
