const currencyFormatter = (number) => {
	const formattedNumber = new Intl.NumberFormat(navigator.language, {
		maximumFractionDigits: 0,
	}).format(Number.parseInt(number));
	return formattedNumber;
};

export const salaryFormatter = (
	currency,
	min_salary,
	max_salary,
	salary_time,
) => {
	let salaryHtml;

	if (currency && salary_time) {
		let currencySymbol = "€";
		if (currency === "GBP") {
			currencySymbol = "£";
		}
		if (currency === "USD") {
			currencySymbol = "$";
		}

		const salaryTimeUnitMapping = {
			year: "per year",
			month: "a month",
			week: "per week",
			day: "a day",
			hour: "an hour",
		};

		const salaryTimeUnitValue = salary_time.toLowerCase();
		const salaryTimeUnit = salaryTimeUnitMapping[salaryTimeUnitValue];

		if (min_salary && max_salary) {
			salaryHtml = `${currencySymbol} ${currencyFormatter(min_salary)} - ${currencySymbol} ${currencyFormatter(max_salary)} ${salaryTimeUnit}`;
		} else if (min_salary && !max_salary) {
			salaryHtml = `Min. ${currencySymbol} ${currencyFormatter(min_salary)} ${salaryTimeUnit}`;
		} else if (!min_salary && max_salary) {
			salaryHtml = `Max. ${currencySymbol} ${currencyFormatter(max_salary)} ${salaryTimeUnit}`;
		}
	}
	return salaryHtml;
};

const insertBeforeLastSlash = (url, insert) => {
	const lastSlashIndex = url.lastIndexOf("/");
	if (lastSlashIndex === -1) {
		return url; // If no slash is found, return the original URL
	}

	// Split the URL into two parts: before the last slash and after
	const beforeLastSlash = url.substring(0, lastSlashIndex);
	const afterLastSlash = url.substring(lastSlashIndex);

	// Insert the variable before the last slash
	return `${beforeLastSlash}-${insert}${afterLastSlash}`;
};

export const jobTpl = (job, jobDetailLink, jobFooter) => {
	const tagsHtml = job.tags
		.map((tag) => `<span class="tag">${tag}</span>`)
		.join("");
	const salaryHtml = salaryFormatter(
		job.currency,
		job.min_salary,
		job.max_salary,
		job.salary_time,
	);

	const jobTpl = `
        ${jobDetailLink ? `<a href="${insertBeforeLastSlash(jobDetailLink, job.job_id)}" rel="nofollow" target="_blank">` : ""}
            <article>
                <header>${job.title}</header>
                 ${job.logo ? `<img src="${job.logo}" alt="JS Job logo for: ${job.title}" class="logo" />` : ""}
				<div class="main">
					<ul class="job-details">
						<li><i data-lucide="map-pin"></i> ${job.city}</li>
						${salaryHtml ? `<li><i data-lucide="banknote"></i> ${salaryHtml}</li>` : ""}
						${job.hours ? `<li><i data-lucide="clock"></i> ${job.hours} Hours per Week</li>` : ""}
						${job.education ? `<li><i data-lucide="graduation-cap"></i> ${job.education}</li>` : ""}
						<li><i data-lucide="building"></i> ${job.company}</li>
					</ul>
				</div>
                <footer class="tags">
                    ${tagsHtml}
                </footer>
                ${jobFooter ? jobFooter : ""}
            </article>
        ${jobDetailLink ? "</a>" : ""}`;

	return jobTpl.trim();
};
